<template>
   <v-card>
      <v-card-title class="py-2 pl-1 pr-3"
         style="color:#666666;font-weight:600;border-bottom: 1px solid #CCCCCC;">
         <v-btn icon
            class="mr-2 text-none"
            @click="$emit('close')">
            <v-icon>mdi-close</v-icon>
         </v-btn>
         Nhân viên
         <v-spacer/>
         <v-btn dark 
            class="px-3 text-none"
            color="primary"
            @click="callSaveUser">
            <b>Lưu</b>
         </v-btn>
      </v-card-title>

      <v-card-text class="px-6 py-1 pb-6"
         style="overflow-y:auto;">
         <v-row class="pt-4"> 

            <v-col cols="12" class="pa-0">
               <v-card-subtitle
                  class="pa-0 py-1 px-4"
                  v-show="showError"
               >
                  <div :style="{'background-color': errForm.color}"
                     style="font-size:16px;font-weight: 600;border-radius:4px;"
                     class="py-1 d-block white--text text-center">
                     {{ errForm.message }}
                  </div>
               </v-card-subtitle>
            </v-col>

            <v-col v-if="user"
               cols="12" class="mt-1 pa-0 px-4">
               <v-text-field
                  ref="fullname"
                  style="font-size:18px;font-weight:600;"
                  v-model="user.fullname"
                  :rules="[rules.required, rules.minFullname]"
                  label="Họ và tên"
                  outlined dense
                  @focus="showKeyboard"
                  ></v-text-field>
            </v-col>

            <v-col v-if="user"
               cols="12" class="mt-1 pa-0 px-4">
               <v-text-field
                  style="font-size:18px;font-weight:600;"
                  v-model="user.mobile"
                  label="Số điện thoại"
                  outlined dense
                  @focus="showKeyboard"
                  ></v-text-field>
            </v-col>

            <v-col v-if="user"
               cols="12" class="mt-1 pa-0 px-4">
               <v-text-field
                  style="font-size:18px;font-weight:600;"
                  v-model="user.email"
                  label="Email"
                  outlined dense
                  @focus="showKeyboard"
                  ></v-text-field>
            </v-col>
         <!--
            <v-col v-if="user"
               cols="12" class="mt-1 pa-0 px-4"
               align="left">
               <div style="font-size:15px;font-weight:550;">
                  <div class="mr-2"
                     :style="{'display': $vuetify.breakpoint.mdAndUp?'inline-block':'block'}"
                     >Quyền truy cập:</div>
                  <v-btn v-for="(role, index) in roles" :key="index"
                     class="mr-2 text-none px-2 my-1"
                     color="teal" dark
                     :outlined="user.role!=role.value"
                     @click="user.role=role.value">
                     {{role.label}}
                  </v-btn>
               </div>
            </v-col>
         -->
         </v-row>
      </v-card-text>
   </v-card>
</template>

<script>
import { mapGetters } from "vuex"
export default {
   props: ['user'],
   computed: {
      ...mapGetters({
        userId: "userId",
        token: "token",
        role: "getLevel",
      }),
      showError() {
        return this.errForm.status
      },
   },
   watch: {
      user: function () {
         this.resetValidate()
      }
   },
   data () {
      return {
         backgroundImg: null,
         backgroundColor: null,
         blurBackground: true,
         errForm: {
            status: false,
            message: "",
            color: 'red'
         },
         rules: {
            required: (value) => !!value || "*Bắt buộc",
            minFullname: (v) => v.length >= 3 || "Ít nhất 3 ký tự",
         },
         roles: [
            { label: 'Quản trị', value:'admin' },
            { label: 'Vận hành', value:'operator' },
            { label: 'Khách', value:'guest' },
         ]
      }
   },
   methods: {
      resetValidate () {
        if (
          this.$refs.fullname != undefined &&
          !this.$refs.fullname.validate()
        ) {
          this.$refs.fullname.reset();
        }
      },
      isValidate () {
        let result = true;
        this.$refs.fullname.validate(true)

        if (
          !this.$refs.fullname.validate()
        ) {
           
          this.errForm.status = true
          setTimeout(() => {
            this.errForm.status = false
          }, 3000);
          this.errForm.color = 'red'
          this.errForm.message = "Lỗi! Thông tin không hợp lệ!"
          result = false;
        }
        return result;
      },
      callSaveUser () {
         if (!this.isValidate()) return;
         if (this.user._id) {
            this.callUpdateUser()
         } else {
            this.callCreateUserAPI()
         }
      },
      callUpdateUser () {
         var self = this
         delete this.user.roleStr
         self.axios
            .put( this.$root.apiUser + `/user-manager/update/user/${this.user._id}`,
               this.user,
               { headers: { "x-token": self.token } })
            .then((res) => {
               // console.log(res.data)
               if (res.data.status == 'OK') {
                  self.errForm.message = "Lưu thành công!"
                  self.errForm.color = '#689F38'
                  self.errForm.status = true
                  setTimeout(function () {
                     self.$emit('saveDone')
                     self.errForm.status = false
                  }, 1000)
               } else {
                  self.errForm.message = res.data.message
                  self.errForm.color = 'red'
                  self.errForm.status = true
               }
            }).catch(err => {
               self.errForm.color = 'orange'
               self.errForm.status = true
               if( err.response ){
                  console.log(err.response.data)
                  self.errForm.message = err.response.data.message
                  return
               }
               self.errForm.message = err
            })
      },
      callCreateUserAPI () {
        var self = this
        let payload = {
          customer_id: this.$root.apiUserCustomerId,
          fullname: this.user.fullname,
         //  username: this.user.username,
          mobile: this.user.mobile,
          email: this.user.email,
          created_by: this.userId
        };
        self.axios
            .post( this.$root.apiUser + `/user-manager/user`,
               payload,
               { headers: { "x-token": self.token } })
            .then((res) => {
               // console.log(res.data)
               if (res.data.status == 'OK') {
                  self.errForm.message = "Lưu thành công!"
                  self.errForm.color = '#689F38'
                  self.errForm.status = true
                  setTimeout(function () {
                     self.$emit('saveDone')
                     self.errForm.status = false
                  }, 1000)
               } else {
                  self.errForm.message = res.data.message
                  self.errForm.color = 'red'
                  self.errForm.status = true
               }
            }).catch(err => {
               self.errForm.color = 'orange'
               self.errForm.status = true
               if( err.response ){
                  console.log(err.response.data)
                  self.errForm.message = err.response.data.message
                  return
               }
               self.errForm.message = err
            })
      },
      showKeyboard (e) {
         this.$eventBus.$emit("requestShowKeyboard", e.target, 'normal')
      }
   }
}
</script>